@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: bold;
  font-weight: 700;
  src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Bold.ttf) format('woff');
}

body {
  margin: 0;
  font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
  padding: 0;
}

html,
div#root,
div.App,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

div#root {
  background-image: url('./media/20220626_045937.jpg');
  background-position: center;
  background-size: cover;
}

div.App  {
  overflow-y: scroll; /* Allows scrolling */
  scrollbar-width: none; /* For Firefox */
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

div.App::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

div#welcomeText {
  width: 700px;
  padding: 20px 10px;
  color: #FFF;
  font-family: 'Ubuntu', sans-serif;
  max-width: 90%;
}

p.greeting-text {
  margin-bottom: 30px;
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
}

#welcomeText h1 {
  margin-bottom: 25px;
}

div#welcomeText h1,
div#welcomeText h2 {
  font-family: Ubuntu , sans-serif;
  font-weight: 700;
  color: #FFF
}

div#welcomeText h1 {
  font-size: 75px;
}

div#welcomeText h1 {
  font-size: 30px;
}

.icons-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.icons-box a img {
  width: 50%;
}

div#topBar {
  background: rgba(0,0,0,0.6);
}

img#abrahmImage {
  width: 200px;
  border-radius: 50%;
}

div#spalshWelcome {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

button#showCarousel {
  font-family: 'Ubuntu', sans-serif;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #FFF;
  color: #FFF;
  padding: 5px 20px;
  font-weight: 700;
  margin: 20px 0 0;
}

div#spalshWelcome.profile-open {
  display: none;
}

.hide-button {
  display: none;
}

#portfolioPopUp {
  display: none;
}

#portfolioPopUp.active {
  display: block;
  height: 100%;
}


div#portfolioExamples {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0 150px;
  flex-wrap: wrap;
  overflow: scroll;
}

div#portfolioExamples {
  background: rgba(245, 245, 245, 0.7);
}

h1.portfolio-header{
  margin: 0;
  padding-top: 20px;
  background: rgba(245, 245, 245, 1);
}

.project img {
  max-width: 100%;
}

.project {
  flex-basis: 40%;
  margin: 2% 5%;
  height: 500px;
}

.project-details {
  background: #FFFFFF;
  height: 300px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-details a {
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0 auto 10px;
  display: block;
  width: fit-content;
  font-family: 'Ubuntu', sans-serif;
  color: #000;
}

.tech-badges {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-evenly;
  margin: 20px auto;
}

.tech-badges .tech-badge {
  width: auto;
  height: 25px;
}

.project-screenshot {
  background: #FFF;
  height: 175px;
  overflow: hidden;
}

span#closePortfolio {
    position: absolute;
    right: 10%;
    top: 90px;
    font-size: 30px;
    font-weight: 700;
}

#welcomeImage {
  margin: 0 0 30px;
}

.hike2hike-icon img {
  border-radius: 50%;
}

.contact-icons a {
  height: 30px;
  width: 30px;
}

.contact-icons a img {
  width: 100%;
}

#contactIcons {
  display: flex;
  width: 80%;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.icons-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 20px 0;
}

.icons-row a {
  width: 50px;
  height: 50px;
}

.icons-row a img {
  width: 100%;
}

#infoTextBox {
  display: none;
}

#splashArea {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-info {
  width: 80%;
  max-width: 600px;
  position: relative;
}

.welcome-info h1 {
  color: #FFFFFF;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
}

div#infoTextBox.active {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 600px;
  background: #000000;
  color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
}

div#infoBoxTitle {
  display: flex;
}

span#closeInfoBox {
  position: absolute;
  right: 20px;
  font-size: 30px;
  z-index: 1;
}

div#infoBoxTitle p {
  display: block;
  width: 100%;
}

div#infoBoxTitle p {
  display: block;
  width: 100%;
  margin: 0 0 20px;
  font-size: 30px;
  font-family: 'Ubuntu', sans-serif;
}