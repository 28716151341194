@media screen and (max-width: 1199px) {
    .project {
        flex-basis: 45%;
        margin: 2% 2%
    }

    .project-details p {
        font-size: 14px;
    }
}

@media screen and (max-width: 980px){

    .project {
        flex-basis: 60%;
    }

    div#portfolioExamples {
        justify-content: center;
    }

}

@media screen and (max-width: 600px){
    div#welcomeText h1 {
        font-size: 30px;
    }

    div#welcomeText h2 {
        font-size: 16px;
    }

    .resume-icon {
        grid-area: resume;
    }

    .email-icon {
        grid-area: email;
    }

    .phone-icon {
        grid-area: phone;
    }

    .github-icon {
        grid-area: github;
    }

    .hike2hike-icon {
        grid-area: h2h;
    }


    .contact-icons .icons-box a {
        width: 50px;
    }

    .icons-box a img {
        width: 30px;
        height: 30px;
        align-self: center;
    }

    .contact-icons {
        height: 60px;
    }

    .project-screenshot {
        height: 150px;
    }

    .project {
        flex-basis: 90%;
    }

    .project-details p {
        font-size: 14px;
    }

    div#portfolioExamples.active{
        padding-bottom: 100px;
    }

    .project-details {
        height: 325px;
    }

    span#closePortfolio {
        top: 70px;
    }

    p.greeting-text {
        font-size: 14px;
    }
}

@media screen and (min-width: 475px) and (max-width: 600px){
   
    .project-details p {
        font-size: 18px;
    }
    
}